import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import { ProjectCardComp } from "./ProjectCardComp";
import projImg1 from "../assets/img/proj_pvz.png";
import projImg2 from "../assets/img/proj_math.png";
import projImg3 from "../assets/img/proj_fleurymich.png";
import projImg4 from "../assets/img/proj_redditech.png";
import projImg5 from "../assets/img/proj_concurrent.png";
import projImg6 from "../assets/img/proj_dogtag.png";
import projImgC1 from "../assets/img/proj_certif_w.png";
import projImgC2 from "../assets/img/proj_letsEncr_w.png";
import projImgC3 from "../assets/img/proj_notif.png";
import projImgC4 from "../assets/img/proj_app.png";
import 'animate.css';
import { useTranslation } from "react-i18next";

export const Projects = () => {
  const {t} = useTranslation();

  const school_project = [
    {

      title: t("title_fleurymichon"),
      description: t("fleurymichon"),
      imgUrl: projImg3,
      link: "https://github.com/JeremyDumesny/FleuryMichon"
    },
    {
      title: t("title_math"),
      description: t("math"),
       imgUrl: projImg2,
      link: "https://github.com/JeremyDumesny/tek2-3_Mathematics" 
    },
    {
      title: t("title_towerdefense"),
      description: t("towerdefense"),
      imgUrl: projImg1,
      link: "https://github.com/JeremyDumesny/tek1_MyDefender"

    },
    {
      title: t("title_redditech"),
      description: t("redditech"),
      imgUrl: projImg4,
      link: "https://github.com/JeremyDumesny/tek3_AppDev-Redditech"
    },
    {
      title: t("title_concurrent"),
      description: t("concurrent"),
      imgUrl: projImg5,
      link: "https://github.com/JeremyDumesny/tek2_Concurrent-Programming"
    },
    {
      title: t("title_end_of_study"),
      description: t("end_of_study"),
      imgUrl: projImg6,
    },
  ];

  const work_project = [

    {
      
      title: t("title_notification_service"),
      description:t("notification_service"),
      imgUrl: projImgC3,
      link: "https://github.com/JeremyDumesny/notification-service"
    },
    {
      title: t("title_lets_encrypt"),
      description: t("lets_encrypt"),
      imgUrl: projImgC2,
      link: "https://github.com/JeremyDumesny/lets-encrypt"
    },
  ];
  const work_project1 = [
    {
      title: t("title_certificate_manager"),
      description: t("certificate_manager"),
      imgUrl: projImgC1,
      link: "https://github.com/JeremyDumesny/certificate-manager"
    },
  ];
  const work_project2 = [
    
    {
      title: t("title_titan_datacenters"),
      description: t("titan_datacenters"),
      imgUrl: projImgC4,
      link: "https://titandc.net/en#:~:text=and%20upgrade%20requests-,%E2%80%A6,-CUSTOMER%20CASES"
    },
  ];

  return (
    <section className="project" id="project">
      <Container>
        <Row>
          <Col size={12}>
              <div className>
                <h2>{t("projects")}</h2>
                <p>{t("projects_description")}</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">{t("missions")}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">{t("studies")}</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className>
                    <Tab.Pane eventKey="third">
                      <Row>
                        {
                          school_project.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                              />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="first">
                    <Col>
                      <Row>
                        <Col size={12} sm={12} md={6} lg={6} xl={4}>
                          {
                            work_project.map((project, index) => {
                              return (
                                <ProjectCardComp
                                  key={index}
                                  {...project}
                                />
                              )
                            })
                          }
                        </Col>
                        <Col size={12} sm={12} md={6} lg={6} xl={4}>
                          {
                            work_project1.map((project, index) => {
                              return (
                                <ProjectCardComp
                                  key={index}
                                  {...project}
                                />
                              )
                            })
                          }
                        </Col>
                        <Col size={12} sm={12} md={6} lg={6} xl={4}>
                          {
                            work_project2.map((project, index) => {
                              return (
                                <ProjectCardComp
                                  key={index}
                                  {...project}
                                />
                              )
                            })
                          }
                        </Col>
                      </Row>
                      </Col>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
