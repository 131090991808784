import { Col } from "react-bootstrap";

export const ProjectCardComp = ({ title, description, imgUrl, link }) => {
  return (
      <a href={link}>
      <div className="proj-imgbx">
        <img src={imgUrl} />
        <div className="proj-txtx">
          <h4 >{title}</h4>
          <span>{description}</span>
        </div>
      </div>
      </a>
  )
}
