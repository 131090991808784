import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Footer } from "./components/Footer";
import { createContext } from 'react';
import React, { useState } from "react";
import i18n from "i18next";

export const ThemeContext = createContext(null)

function App() {
  const [theme, setTheme] = useState("dark")
  const [imageUrl, setImageUrl] = useState(i18n.language === "fr" ? "../assets/img/fr.svg" : "../assets/img/en.svg");

  const toogleTheme = () => {
    setTheme((curr) => (curr === "light" ? "dark" : "light"))
  }
  return (
    <ThemeContext.Provider value={{ theme, toogleTheme }}>
      <div className="App" id={theme}>
        <NavBar  toggleTheme={toogleTheme} theme={theme} imageUrl1={"../assets/img/fr.svg"} imageUrl2={"../assets/img/gb.svg"}/>
        <Banner />
        <Skills />
        <Projects />
        <Footer />

      </div>
    </ThemeContext.Provider>
  );
}

export default App;
