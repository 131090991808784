import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo1 from '../assets/img/newLogo.svg';
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon4 from '../assets/img/lightbulb-regular.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import flag_fr from '../assets/img/fr.svg';
import flag_gb from '../assets/img/gb.svg';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from "react-i18next";
import {
  BrowserRouter as Router
} from "react-router-dom";
import Switch from "react-switch";
import i18n from "i18next";
import React, { useContext } from "react";
import { ThemeContext } from "../App";


export const NavBar = (props) => {
  const {t} = useTranslation();
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [imageUrl, setImageUrl] = useState(i18n.language === "fr" ? flag_fr : flag_gb);
  const [activeLink, setActiveLink] = useState('home');
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  }
  const currentLanguage = i18n.language;

  function changeImg() {
    console.log(imageUrl)
    if (imageUrl === "../assets/img/fr.svg"){
      setImageUrl("../assets/img/gb.svg")
    } else {
      setImageUrl("../assets/img/fr.svg")
    }
    i18n.language === "fr" ? i18n.changeLanguage("en") : i18n.changeLanguage("fr")
  }



  return (

    <Router>
      <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
        <Container>
          <Navbar.Brand href="/">
            <img src={logo1} alt="Logo1" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="navbar-toggler-icon"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="#home" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>{t("home")}</Nav.Link>
              <Nav.Link href="#skills" className={activeLink === 'skills' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('skills')}>{t("skills")}</Nav.Link>
              <Nav.Link href="#project" className={activeLink === 'project' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('project')}>{t("projects")}</Nav.Link>
            </Nav>
            <span className="navbar-text">

              <div className="social-icon">
                <a id="linkedin" href="https://www.linkedin.com/in/j%C3%A9r%C3%A9my-dumesny/"><img src={navIcon1} alt="" /></a>
                <a id="github" href="https://github.com/JeremyDumesny"><img src={navIcon2} alt="" /></a>

              </div>

              <button className="vvd" onClick={() => window.location = 'mailto:jeremydumesny.pro@gmail.com'}>{t("email")}</button>
              <div className="switch">
                <a onClick={props.toggleTheme}><img src={navIcon4} alt="" /></a>
              </div>
              <div className="langage" >
                <a id={currentLanguage} onClick={changeImg}><img src={imageUrl} alt="" /></a>
              </div>
            </span>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Router>
  )
}


export default NavBar;