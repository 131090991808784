import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { useTranslation } from "react-i18next";
import CV from '../assets/img/CV-Jérémy-Dumesny.pdf';

import React from "react";
import { Link } from "react-router-dom";

export const Banner = () => {
  const {t} = useTranslation();
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(300 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [t("banner_data_rotate1"), t("banner_data_rotate2"),t("banner_data_rotate3"),t("banner_data_rotate4")];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const ButtonMailto = ({ mailto, label }) => {
    return (
      <Link
        to='#'
        onClick={(e) => {
          window.location.href = mailto;
          e.preventDefault();
        }}
      >
        {label}
      </Link>
    );
  };

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(500);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <TrackVisibility>
            {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <h1>{t("banner_title")} <span className="txt-rotate" dataPeriod="1000" data-rotate='[ "Flutter Developer", "C/C++ Developer", "Front-End Developer ]'><span className="wrap">{text}</span></span></h1>
                <p>{t("banner_msg")}</p>
                <a href={CV} download="CV Jérémy Dumesny" target='_blank' >
                  <button>{t("cv")}<ArrowRightCircle size={25} /></button>
                </a>
              </div>}
          </TrackVisibility>

        </Row>
      </Container>
    </section>
  )
}
