import meter50 from "../assets/img/meter50.svg";
import meter55 from "../assets/img/meter55.svg";
import meter60 from "../assets/img/meter60.svg"
import meter70 from "../assets/img/meter70.svg";
import meter75 from "../assets/img/meter75.svg";
import meter85 from "../assets/img/meter85.svg"
import meter90 from "../assets/img/meter90.svg"
import meter99 from "../assets/img/meter99.svg"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"
import { useTranslation } from "react-i18next";

export const Skills = () => {
  const {t} = useTranslation();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1800 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 1800, min: 1024 },
      items: 4
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 3
    },
    tablet2: {
      breakpoint: { max: 767, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>{t("skills")}</h2>
                        <p>{t("skills_description")}</p>
                        <Carousel responsive={responsive} infinite={true} 
  className="owl-carousel owl-theme skill-slider">
                            <div className="item" id="flutter">
                                <img src={meter85} alt="Image" />
                                <h5>Flutter</h5>
                            </div>
                            <div className="item" id="golang">
                                <img src={meter70} alt="Image" />
                                <h5>Golang</h5>
                            </div>
                            <div className="item" id="c">
                                <img src={meter75} alt="Image" />
                                <h5>C/C++</h5>
                            </div>
                            <div className="item" id="python">
                                <img src={meter70} alt="Image" />
                                <h5>Python</h5>
                            </div>
                            <div className="item" id="react">
                                <img  src={meter55} alt="Image1" />
                                <h5>React</h5>
                            </div>
                            
                            <div className="item" id="git">
                                <img src={meter90} alt="Image" />
                                <h5>Git</h5>
                            </div>
                            <div className="item" id="dock">
                                <img src={meter60} alt="Image" />
                                <h5>Docker</h5>
                            </div>
                            <div className="item" id="illu">
                                <img src={meter70} alt="Image" />
                                <h5>Illustrator</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}
